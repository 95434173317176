

export const images = [
    { original: "/images/tutlid-view.jpg", thumbnail: "/images/tutlid-view.jpg" },
    { original: "/images/tutlid.jpg", thumbnail: "/images/tutlid.jpg" },
    { original: "/images/tutlid-1.jpg", thumbnail: "/images/tutlid-1.jpg" },
    { original: "/images/tutlid-2.jpg", thumbnail: "/images/tutlid-2.jpg" },
    { original: "/images/tutlid-3.jpg", thumbnail: "/images/tutlid-3.jpg" },
    { original: "/images/tutlid-4.jpg", thumbnail: "/images/tutlid-4.jpg" },
    { original: "/images/tutlid-5.jpg", thumbnail: "/images/tutlid-5.jpg" },
];